<template src="./CreateVoucherCampaign.html"></template>
<script>
import createVoucherWithEmail from "../createVoucherWithEmail/createVoucherWithEmail.vue";
import createVoucherWithBarcode from "../createVoucherWithBarcode/createVoucherWithBarcode.vue";
import createVoucherWithDigitalPass from "../createVoucherWithDigitalPass/createVoucherWithDigitalPass.vue";

export default {
  components: {
    createVoucherCampaignWithEmail: createVoucherWithEmail,
    createVoucherCampaignWithBarcode: createVoucherWithBarcode,
    createVoucherCampaignWithDigitalPass: createVoucherWithDigitalPass,
  },
  data() {
    return {
      type: "1",
    };
  },
  mounted() {
    this.type = +this.$route.query.voucher_type;
  },
  methods: {},
};
</script>

<style>

</style>
